import React from 'react'
import Layout from '@components/layouts/default/Layout'
import ServicesWrapper from '@components/ui/wrappers/ServicesWrapper'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import { OpenModalContext } from '@components/layouts/default/Layout'
import modalContactTypes from '@configs/modalContactTypes'

const Terms = ({ intl }) => {
  return (
    <Layout pageName="terms-of-service">
      <OpenModalContext.Consumer>
        {(value) => {
          return (
            <ServicesWrapper>
              <h1>{intl.formatMessage({ id: 'terms-of-service.title' })}</h1>
              <p className="marginBottom">{intl.formatMessage({ id: 'terms-of-service.lastUpdated' })}</p>
              <p>{intl.formatMessage({ id: 'terms-of-service.thankYou' })}</p>
              <p>
                {intl.formatMessage({ id: 'terms-of-service.theWebsite' })}
                <Link className="inkInside" to="/">
                  ideabuddy.com
                </Link>
                {intl.formatMessage({ id: 'terms-of-service.site' })}
              </p>
              <p>
                {intl.formatMessage({ id: 'terms-of-service.theseTerms' })}
                <Link to="/privacy-policy" className="leftElement">
                  https://ideabuddy.com/privacy-policy
                </Link>
                {intl.formatMessage({ id: 'terms-of-service.ifYoy' })}
              </p>
              <p>{intl.formatMessage({ id: 'terms-of-service.uponEntering' })}</p>
              <p>{intl.formatMessage({ id: 'terms-of-service.weMayUpdate' })}</p>
              <p>
                {intl.formatMessage({ id: 'terms-of-service.theServiceIsProvided' })}
                <span className="leftElement underline">
                  {intl.formatMessage({ id: 'terms-of-service.underNoCircumstances' })}
                </span>
              </p>
              <p>{intl.formatMessage({ id: 'terms-of-service.weAskThat' })}</p>
              <div className="sectionLeft">
                <h2>{intl.formatMessage({ id: 'terms-of-service.1ServiceAndSite' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.byAgreeing' })}</p>
                <ul className="circle">
                  {intl.formatMessage({ id: 'terms-of-service.eligibility' })}
                  <li>{intl.formatMessage({ id: 'terms-of-service.youHaveNot' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.youAre18' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.ifTheService' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioHasNot' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.youDoNotReside' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.youAreUsingTheService' })}</li>
                </ul>
                <p>
                  {intl.formatMessage({ id: 'terms-of-service.personalUseOnly' })}
                  <Link to="/pricing" className="leftElement">
                    https://ideabuddy.com/pricing
                  </Link>
                  {intl.formatMessage({ id: 'terms-of-service.thatAllowsYou' })}
                </p>
                <ul className="circle">
                  {intl.formatMessage({ id: 'terms-of-service.appropriateAndRestrictedUse' })}
                  <li>{intl.formatMessage({ id: 'terms-of-service.impairInterfereWith' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.harassIntimidate' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.infringeUponOrViolate' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.useAutomatedMeans' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.createDatabase' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.sanctionInstigateAllow' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.useTheServicesForAnyIllegal' })}</li>
                </ul>
                <p>{intl.formatMessage({ id: 'terms-of-service.youShallNotCreate' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.thisIncludesTransmitting' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.noLicense' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.2ProfileInformationAndAccount' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.youAgreeAndUnderstand' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.verifyingYourAccount' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.ifYouRegisterThroughElectronicMail' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.securingYourAccount' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.preservingTheConfidentiality' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.oneAccountForOnePerson' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.ifYouAreCompanyOrAreUsingTheAccount' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.youHerebySignifyAndAcknowledge' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.youAlsoAcknowledge' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.sharingYourAccountCredentials' })}</p>
                <p>
                  {intl.formatMessage({ id: 'terms-of-service.notificationOfAbuse' })}
                  <span
                    onClick={() => {
                      value.openContact(modalContactTypes.CONTACT)
                    }}
                    className="spanLink leftElement"
                    aria-hidden="true"
                  >
                    contact@ideabuddy.com
                  </span>
                  {intl.formatMessage({ id: 'terms-of-service.inAnyInstanceThatCauses' })}
                </p>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioLiability' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.3ContentOwnership' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.allRightTitleInterestAndOwnership' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.intellectualPropertyRights' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.restrictionsExceptAsExplicitlyPermitted' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.subjectToTheTermsAndConditions' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioDoesNotClaim' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.allOfEntriesAndUploads' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioReservesTheRight' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioWillOnlyRetain' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioWillNotBeResponsible' })}</p>

                <h2>{intl.formatMessage({ id: 'terms-of-service.4Feedback' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.youMayButAreNotRequiredTo' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioShallOwnAllRights' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.youAcknowledgeThatAnyIdeas' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.5User' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.youAreSolelyResponsible' })}</p>
                <ul className="circle">
                  {intl.formatMessage({ id: 'terms-of-service.byUploadingStoring' })}
                  <li>{intl.formatMessage({ id: 'terms-of-service.theContentAndAnyUse' })}</li>
                  <li>{intl.formatMessage({ id: 'terms-of-service.theContentDoesNotContain' })}</li>
                </ul>
                <h2>{intl.formatMessage({ id: 'terms-of-service.6NoLiability' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioShallNotBeLiable' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.suchDamagesInclude' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.toTheExtentPermissible' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.theForegoingRestrictions' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.youHerebyAcknowledge' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.notwithstandingAnything' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.userFullyRecognizes' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.theServiceSiteAndContent' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.7Advertising' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.theSiteMayDisplayAds' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.8Modifying' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioMayModifyOrChange' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.9Payment' })}</h2>
                <p>
                  {intl.formatMessage({ id: 'terms-of-service.whenSubscribingToSubscriptionPlan' })}
                  <Link to="/pricing" className="leftElement">
                    https://ideabuddy.com/pricing
                  </Link>
                  {intl.formatMessage({ id: 'terms-of-service.youAreRequired' })}
                  <a href="https://paddle.com" target="_blank" rel="noopener noreferrer" className="leftElement">
                    https://paddle.com
                  </a>
                  .
                </p>
                <p>{intl.formatMessage({ id: 'terms-of-service.whenYouPurchaseSubscriptionPlan' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.ratesDeepBlueSeaStudio' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.theServiceIsBilled' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.allFeesAreExclusiveOfAllTaxes' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.allYourEntriesInTheIdeaBuddy' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.forAnyUpgradeOrDowngrade' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.10Termination' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioAccordingToItsSoleDiscretion' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.theseTermsMayBeTerminated' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.youHerebyUnderstandAndAgree' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.moreoverDeepBlueSeaStudio' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.howeverInAnyCase' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.11Reimbursement' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.youHerebyGuarantee' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.12EntireAgreement' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.theseTermsConstituteTheEntireAgreement' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.13Assignment' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.deepBlueSeaStudioMayAssignAnyAndAllRights' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.14GoverningLaw' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.theseTermsOfServiceShallBeExclusivelyGoverned' })}</p>
                <p>{intl.formatMessage({ id: 'terms-of-service.notwithstandingTheForegoing' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.15NoClassAction' })}</h2>
                <p>{intl.formatMessage({ id: 'terms-of-service.youAgreeNotToUndertake' })}</p>
                <h2>{intl.formatMessage({ id: 'terms-of-service.16SystemRequirements' })}</h2>
                <ul className="circle">
                  {intl.formatMessage({ id: 'terms-of-service.ideaBuddyWorksOnTheMostRecent' })}
                  <li>Chrome</li>
                  <li>Safari</li>
                  <li>Firefox</li>
                  <li>Edge</li>
                </ul>
                <p>{intl.formatMessage({ id: 'terms-of-service.ideaBuddyRequiresThatCookies' })}</p>
              </div>
              <div className="address">
                <p>
                  {intl.formatMessage({ id: 'terms-of-service.youMayContactUs' })}
                  <span
                    onClick={() => {
                      value.openContact(modalContactTypes.CONTACT)
                    }}
                    className="spanLink leftElement"
                    aria-hidden="true"
                  >
                    contact@ideabuddy.com
                  </span>
                  {intl.formatMessage({ id: 'terms-of-service.orByMail' })}
                </p>
                <p className="marginTop">Idea Buddy</p>
                <p>1049 El Monte Avenue, Ste C #601</p>
                <p>Mountain View, CA 94040</p>
                <p>USA</p>
              </div>
            </ServicesWrapper>
          )
        }}
      </OpenModalContext.Consumer>
    </Layout>
  )
}

export default injectIntl(Terms)
